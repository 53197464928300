import Error404PageConfig from 'app/main/errors/404/Error404PageConfig';
import LoginPageConfig from '../Login/LoginPageConfig';
import Register3PageConfig from '../register-3/Register3PageConfig';
import HomePageConfig from '../Home/HomePageConfig';

import customerAppConfigs from './CustomerMaster/customerAppConfig';
import ResetPasswordPageConfig from '../Reset_Password/ResetPasswordConfig'
import CartAppConfigs from './cartMaster/cartAppConfig';
import ContactConfig from '../Contact_Us/ContactConfig';
import UsersConfigs from './Users/UsersConfigs';
import AccountUserConfigs from './AccountUser/AccountUserConfigs';
import SettingsConfigs from './Settings/SettingsConfigs';
import ListPricesConfigs from './ListPrices/ListPricesConfigs'
import FaqConfigs from './FAQ/FaqConfigs'
import ContactUsConfigs from './ContactUs/ContactUsConfigs'
import ReportsConfigs from './Reports/ReportsConfigs';




const appsConfigs = [
	Error404PageConfig,
	LoginPageConfig,
	Register3PageConfig,
	HomePageConfig,
	ContactConfig,
	UsersConfigs,
	AccountUserConfigs,
	customerAppConfigs,
	ResetPasswordPageConfig,
	CartAppConfigs,
	SettingsConfigs,
	ListPricesConfigs,
	FaqConfigs,
	ContactUsConfigs,
	ReportsConfigs
];

export default appsConfigs;
